@import "../../assets/sass/mixins.scss";
.bids {
  &__top {
    display: flex;
    align-items: center;
    column-gap: var(--space-2xs);
    justify-content: space-between;
    padding: var(--bids-padding) var(--bids-padding) 0 var(--bids-padding);

    @include screen(lg) {
      padding: var(--bids-padding-md);
      margin-bottom: var(--space-2s);

      border-bottom: var(--bids-bottom-border-width) solid
        var(--bids-bottom-border-color);
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-3s);
    padding: var(--bids-padding);

    @include screen(lg) {
      flex-direction: row;
      justify-content: space-between;
      column-gap: 40px;
      padding: var(--bids-padding-md);
    }
  }
  &__body-col {
    @include screen(lg) {
      width: 251px;
      flex: 0 0 251px;
    }
    &:first-child {
      @include screen(lg) {
        width: 165px;
        flex: 0 0 165px;
      }
    }
    &:last-child {
      @include screen(lg) {
        width: 251px;
        flex: 0 0 251px;
      }
    }
    .user-info-card__item {
      @include screen(lg) {
        flex-wrap: wrap;
      }
    }
  }
  &__info {
    display: flex;
    flex-direction: column;

    @include screen(lg) {
      row-gap: var(--space-xs);
    }
  }
  &__actions-mob {
    align-self: normal;

    @include screen(lg) {
      display: none;
    }
  }
  &__info-time {
    display: inline-flex;
    column-gap: var(--space-xs);

    .text {
      font-weight: 400;
    }

    @include screen(lg) {
      column-gap: var(--space-m);
    }
  }
  &__info-time-date {
    white-space: nowrap;
    word-break: keep-all;
  }
  &__info-list-title {
    &:not(:last-child) {
      margin-bottom: var(--space-2xs);

      @include screen(lg) {
        column-gap: var(--space-3s);
      }
    }
  }
  &__info-list {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-xs);
  }
  &__info-item {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-xs);
  }
  &__info-item--mob {
    @include screen(lg) {
      display: none;
    }
  }

  &__info-actions,
  &__bottom {
    padding: var(--bids-actions-padding);

    border-top: var(--bids-bottom-border-width) solid
      var(--bids-bottom-border-color);

    @include screen(lg) {
      padding: var(--bids-actions-padding-md);
    }
  }
  &__info-actions {
    display: flex;
    flex-direction: column;
    gap: var(--space-2xs);

    text-align: center;

    @include screen(md) {
     flex-direction: row;
     justify-content: space-between;
     align-items: flex-start;

     text-align: left;
    }
  }

  &__info-action-right{
    @include screen(md) {
      margin-right: 0;
      margin-left: auto;
     }
  }

  &__groups{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: var(--space-xs);
    @include screen(md) {
      align-items: flex-start;
     }
  }

  &__info-number{
    display: flex;
    align-items: center;
    gap: var(--space-2xs);
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    // flex-wrap: wrap;
    column-gap: var(--space-s);
    row-gap: var(--space-s);

    text-align: center;

    @include screen(md) {
      flex-direction: row;
      justify-content: space-between;

      text-align: left;
    }
  }
  &__actions-left {
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--space-xs);
    row-gap: var(--space-xs);
    order: 2;

    @include screen(md) {
      order: 1;
    }

    .btn--flat-secondary {
      .icon-text {
        position: relative;
      }
    }

    @include screen(xl) {
      column-gap: var(--space-3m);
    }
  }
  &__actions-right {
    display: flex;
    align-items: center;
    gap: var(--space-2s);
    flex-wrap: wrap;
    order: 1;

    text-align: center;

    @include screen(md) {
      order: 2;

      text-align: left;
    }
    .btn {
      width: 100%;

      @include screen(md) {
        width: auto;
      }
    }
  }
  &__timer{
    flex: 1 1 auto;
  }
  &__msg {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-s);
  }
  &__msg-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--space-s);
  }
  &__chat {
    display: none;
    margin-top: var(--space-3s);

    background-color: var(--bids-chat-bg-color);
    border: var(--bids-bottom-border-width) solid
      var(--bids-bottom-border-color);
    border-radius: var(--bids-border-radius);

    @include screen(lg) {
      margin-top: var(--space-4s);
    }

    &--open {
      display: block;
    }

    .chat-text-area__area {
      height: 64px;

      @include screen(esm) {
        height: var(--chat-text-area-height);
      }
    }
    .chat-text-area {
      .btn--icon {
        min-height: auto;
        height: auto;
      }
    }
    .chat-text-area__button {
      bottom: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      transform: translateY(50%);

      @include screen(esm) {
        height: var(--chat-text-area-height);
      }
    }
  }
  &__item-number-messages {
    position: absolute;
    top: var(--chat-count-bids-item-position-top);
    right: var(--chat-count-bids-item-position-right);
  }
}
.bids-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: var(--bids-info-gap);

  @include screen(lg) {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .text:last-child {
    text-align: right;

    @include screen(lg) {
      text-align: left;
    }
  }
}
.bids-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: var(--space-2s);
  row-gap: var(--space-2s);

  @include screen(lg) {
    column-gap: 0;
    row-gap: var(--space-3m);
  }

  .operation-state {
    flex: 1 1 100%;

    border-radius: var(--bids-border-radius);
  }
}
